var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "authentication-layout" },
    [
      _c(
        "v-layout",
        {
          staticClass: "content",
          attrs: {
            column: "",
            "justify-space-between": "",
            "align-center": "",
            "fill-height": "",
          },
        },
        [
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "form", attrs: { md12: "" } },
            [
              _c("ima-logo", { attrs: { type: "dark" } }),
              _c(
                "v-card",
                {
                  staticClass: "elevation-10 card-form",
                  attrs: { width: "520", "max-width": "740" },
                },
                [_vm._t("form-header"), _vm._t("form-content")],
                2
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "navigation" },
            [_vm._t("navigation")],
            2
          ),
        ],
        1
      ),
      _c("aside", { staticClass: "sidebar" }, [_c("mtr-brand")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }