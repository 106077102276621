<template>
  <section class="authentication-layout">
    <v-layout
      class="content"
      column
      justify-space-between
      align-center
      fill-height
    >
      <v-spacer />

      <v-flex class="form" md12>
        <ima-logo type="dark" />

        <v-card
          class="elevation-10 card-form"
          width="520"
          max-width="740"
        >
          <slot name="form-header" />
          <slot name="form-content" />
        </v-card>
      </v-flex>

      <v-flex class="navigation">
        <slot name="navigation" />
      </v-flex>
    </v-layout>

    <aside class="sidebar">
      <mtr-brand />
    </aside>
  </section>
</template>

<script>
import MtrBrand from '@/components/base/MtrBrand.vue';
import ImaLogo from '@/components/base/ImaLogo.vue';

export default {
  name: 'AuthenticationLayout',
  components: {
    MtrBrand,
    ImaLogo,
  },
};
</script>

<style lang="scss">
.authentication-layout {
  & {
    display: grid;
    grid-template-areas: "content sidebar";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow: hidden;
    background: url("../assets/waves-green-background.png") bottom no-repeat;
    background-size: cover;
  }

  > .content {
    & {
      grid-area: content;
      padding: 2rem 0 1rem;
    }

    > .form {
      & {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .ima-logo {
        margin-bottom: 2rem;
      }
    }

    .card-form {
      & {
        padding: 3rem 2rem 1rem 2rem;
      }

      > .header {
        text-align: center;

        .icon.-colour {
          margin-bottom: 2rem;
        }
      }

      > .card > .action {
        justify-content: space-around;
      }
    }

    > .navigation {
      & {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      > .text {
        color: #7e7e7e;
      }
    }
  }

  > .sidebar {
    & {
      grid-area: sidebar;
      padding: 2rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
</style>
