<template>
  <authentication-layout>

    <template slot="form-content">

      <v-dialog
        persistent
        v-model="dialogAlreadyRegistred"
        width="500"
      >
        <v-card>
          <v-card-text>
            <v-layout column>
              <v-flex
                class="text-xs-center"
                xs12>
                <v-icon
                  color="yellow darken-2"
                  size="94"
                >
                  mdi-alert-circle-outline
                </v-icon>
              </v-flex>
              <v-flex xs12>
                <h3
                  class="text-xs-center"
                >
                  Entidade já cadastrada
                </h3>
              </v-flex>
              <v-flex xs12>
                <p
                  class="text-xs-center"
                >
                  Essa entidade já está cadastrada no sistema.
                  Você deverá utilizar a opção 'Vinculado'.
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
<!--            <v-btn-->
<!--              color="red"-->
<!--              flat-->
<!--              @click="dialogAlreadyRegistred = false"-->
<!--            >-->
<!--              Cancelar-->
<!--            </v-btn>-->
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1 white--text"
             :to="'/cadastro/vinculado'"
            >
              Ir para Vinculado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-stepper
        alt-labels
        v-model="stepper">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
          >
            Cadastro da Entidade
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
            class="teasasd"
          >
            Cadastro do Responsável
          </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <create-entity-form :formEntity="formEntity" @formIsValid="entityFormIsValid"/>

            <v-layout
              justify-space-between
            >
              <v-btn
                elevation-1
                color="white green--text"
                small
                :to="'/cadastro'"
              >
                Voltar
              </v-btn>

              <v-btn
                :disabled="!entityFormValid"
                small
                color="green darken-1 white--text"
                @click="stepper = 2"
              >
                Prosseguir
              </v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              class="mb-5"
            >
              <v-form
                v-model="userFormValid"
              >
                <v-container>
                  <v-layout column>
                    <v-flex>
                      <v-text-field
                        label="CPF"
                        v-model.trim="formUser.cpf"
                        :rules="[validationRules.required, validationRules.cpf]"
                        required
                        box
                        mask="###.###.###-##"
                      />
                    </v-flex>

                    <v-flex>
                      <v-text-field
                        label="Nome"
                        v-model="formUser.name"
                        :rules="[validationRules.required]"
                        box
                        required
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        label="E-mail"
                        v-model="formUser.email"
                        type="email"
                        box
                        :rules="[validationRules.required, validationRules.email]"
                        required
                      />
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="hidePass = !hidePass"
                        :type="hidePass ? 'password' : 'text'"
                        name="password"
                        v-model="formUser.password"
                        box
                        label="Senha"
                        hint="Mínimo de 8 caracteres"
                        min="8"
                        count
                        :rules="[validationRules.required, validationRules.passwordMinLength]"
                      />
                    </v-flex>

                  </v-layout>
                </v-container>

              </v-form>
            </v-card>

            <v-layout justify-space-around>
              <v-btn
                elevation-1
                color="white green--text"
                small
                :disabled="loading"
                @click="stepper = 1"
              >
                Voltar
              </v-btn>

              <v-btn
                :loading="loading"
                :disabled="!userFormValid || !entityFormValid || entityAlreadyRegistred"
                small
                color="green darken-1 white--text"
                @click="save()"
              >
                Cadastrar
              </v-btn>
            </v-layout>

          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>

    <template slot="navigation">
      <v-btn flat small color="primary" class="link" :to="'/login'">
        <v-icon left>mdi-account-circle</v-icon>
        <span class="text">Voltar ao login</span>
      </v-btn>
    </template>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import CreateEntityForm from '@/components/person/CreateEntityForm.vue';
import validationRules from '@/mixins/validation-rules';
import masks from '@/utils/masks';
import authService from '@/services/auth-service';

export default {
  name: 'Entity',
  data() {
    return {
      stepper: 1,
      entityFormValid: false,
      userFormValid: false,
      entityAlreadyRegistred: false,
      formEntity: { postalCode: '', cpf_cnpj: '', profile: [] },
      formUser: {},
      hidePass: true,
      validationRules,
      masks,
      loading: false,
      dialogAlreadyRegistred: false,
    };
  },
  components: {
    AuthenticationLayout,
    CreateEntityForm,
  },
  mixins: [validationRules],
  methods: {
    save() {
      const entityPayload = {
        person: {
          name: this.formEntity.name,
          cpf_cnpj: this.formEntity.cpf_cnpj,
          address_attributes: {
            zip_code: this.formEntity.postalCode,
            street: this.formEntity.street,
            number: this.formEntity.number,
            neighborhood: this.formEntity.neighbourhood,
            complement: this.formEntity.address_details,
            city_id: this.formEntity.city.id,
            federal_unit_id: this.formEntity.state.id,
          },
          profile: this.formEntity.profile,
        },
      };
      const payload = { ...this.formUser, ...entityPayload };

      authService
        .entityRegister(payload)
        .then(() => {
          this.$router.push({ name: 'registerSuccess' });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {

        });
    },
    entityFormIsValid(value) {
      this.entityFormValid = value;
    },
  },
};
</script>

<style lang="scss">
  .v-stepper__content {
    padding: 0 0 16px;
  }

  .v-stepper__label {
    text-align: center !important;
  }

  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: auto;
  }

  .v-stepper, .v-stepper__header {
    box-shadow: none;
  }
</style>
